<template>
  <div class="xiaofan">

    <div>

      <h3 style="font-size: 30px; text-align: center; font-weight: bold; color: #303133; margin:12px"> 关于 ARMA 模型的相关说明
      </h3>
      <el-steps :active="4" align-center>
        <el-step title="数据指标" description="这是一段很长很长很长的描述性文字"></el-step>
        <el-step title="模型介绍" description="这是一段很长很长很长的描述性文字"></el-step>
        <el-step title="模型训练" description="这是一段很长很长很长的描述性文字"></el-step>
        <el-step title="模型部署" description="这是一段很长很长很长的描述性文字"></el-step>
      </el-steps>
    </div>

    <div style="margin-top:12px; ">
      <h3 style="font-size: 22px; text-align: left; font-weight: bold; color: #303133; margin:12px"> 一、数据指标</h3>

      <el-table :data="tableData" height="250" border style="width: 100%; ">
        <el-table-column prop="month" label="月份">
        </el-table-column>
        <el-table-column prop="name" label="品牌名称">
        </el-table-column>
        <el-table-column prop="cost" label="成本">
        </el-table-column>
        <el-table-column prop="sales_no" label="销售数量">
        </el-table-column>
      </el-table>
    </div>

    <div style="margin-top:12px; ">
      <h3 style="font-size: 22px; text-align: left; font-weight: bold; color: #303133; margin:12px"> 二、模型介绍</h3>

      <el-col :span="8">
        <el-input v-model="testdata.language" placeholder='testdata.language'></el-input>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" @click="test5" style="margin-left:12px;">数据获取接口1</el-button>
      </el-col>

      <el-col :span="8">
        <el-input v-model="testdata.language" placeholder='testdata.language'></el-input>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" @click="test6" style="margin-left:12px;">数据获取接口2</el-button>
      </el-col>

      <el-button type="primary" @click="test7">数据获取接口3</el-button>
      <el-button type="primary" @click="test8">数据获取接口8</el-button>


      <br>
      <h2 class="model-name">{{ testdata.language }}</h2>
    </div>




  </div>

</template>




<script>
import baseUrl from '../../../util/config'
// let baseUrl = 'http://localhost:8081'

export default {
  data() {
    return {
      tableData: [],
      tableData222: [],
      testdata: ''
    };
  },
  mounted() {
    this.getTableData(),
      this.test5()
  },

  methods: {
    //数据指标
    getTableData() {
      this.$axios({
        method: 'get',
        url: baseUrl + '/arima_model/model_data',
        headers: {
          'Content-type': 'application/json; charset=utf-8'
        }
      }).then(
        res => {
          console.log(res.data)
          this.tableData = res.data
        },
        err => { }
      )
    },

    test5() {
      this.$axios({
        method: 'get',
        url: baseUrl + '/arima_model/test5',
        headers: {
          'Content-type': 'application/json; charset=utf-8'
        }
      }).then(
        res => {
          console.log(res.data)
          this.testdata = res.data
        },
        err => { }
      )
    },

    test6() {
      this.$axios({
        method: 'get',
        url: baseUrl + '/arima_model/test6',
        headers: {
          'Content-type': 'application/json; charset=utf-8'
        }
      }).then(
        res => {
          console.log(res.data)
          this.testdata = res.data
        },
        err => { }
      )
    },

    test7() {
      let key4index = 777777777777
      this.$axios({
        method: 'get',
        url: baseUrl + '/arima_model/test7?key=' + key4index,
        headers: {
          'Content-type': 'application/json; charset=utf-8'
        }
      }).then(
        res => {
          console.log(res.data)
          this.testdata = res.data
        },
        err => { }
      )
    },


    test8() {
      let dataFarm = {
        id: 'this.id',
        model_name: 'this.data.nodeList[0].modelName',
        //  model_name: "模型名字保存不上的bug",
        model_content: {
          nodeList: 'this.data.nodeList',
          lineList: 'this.data.lineList'
        },
      }

      this.$axios({
        method: 'post',
        url: baseUrl + '/arima_model/test8',
        headers: {
          'Content-type': 'application/json; charset=utf-8'
        },
        data: dataFarm
      }).then(
        res => {
          console.log(res.data)
          this.testdata = res.data
        },
        err => { }
      )
    }

  }
}
</script>

<style scoped>
.xiaofan {
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  margin-bottom: 5px;
  padding: 0 10px;
  /* 给gutter留padding */
}

.el-row {
  margin-bottom: 20px;
}


h3 {
  font-weight: 400;
  color: #3d1f23;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}
</style>